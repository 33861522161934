
.reward-catalog-button {
  min-width: 250px;
  text-align: center;
  height: 0;
  position: relative;
  border-right: 5px solid transparent;
  border-top: 36px solid #007bff;
  border-left: 5px solid transparent;
  box-sizing: content-box;
  box-shadow: none;
  border-bottom: 0;

  &:active, &:focus {
    outline: 0;
  }
}

.reward-catalog--collapse-item {
  .reward-catalog-button {
    text-align: left;
  }
}

.reward-catalog-button__inner {
  position: absolute;
  top: -30px;
  left: 0;
  color: #fff;
  width: 100%;

}

.reward-catalog-button__inner__icon {
  font-size: $font-size-sm;
  margin-right: 0.25rem;
}

.reward-catalog-button__inner__copy {
  display: inline;
  font-size: $font-size-sm;
}

.reward-catalog-button--hidden-sm {
  display: inline;
}

.reward-catalog-button__inner__points {
  font-size: 0.6875rem;
  font-style: italic;
}

.reward-catalog-button--show-md {
  display: none;
}

@include media-breakpoint-only(md) {
  .reward-catalog-button {
    min-width: 100px;
    text-align: center;
    height: 0;
    position: relative;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    box-sizing: content-box;
  }

  .reward-catalog-button__inner {
    position: absolute;
    top: -30px;
    left: 0;
    color: #fff;
    width: 100%;
  }

  .reward-catalog-button--show-md {
    display: inline;
  }

  .reward-catalog-button__inner__icon {
    font-size: $font-size-sm;
    margin-right: 0.25rem;
  }

  .reward-catalog-button--hidden-sm {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .reward-catalog--collapse-item.nav-item {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .reward-catalog-button {
    height: 36px;
    border: none !important;

    &:hover {
      text-decoration: none;
    }
  }

  .reward-catalog-button__inner {
    position: relative;
    top: 0;
    left: 0;
    line-height: 36px;
  }
}