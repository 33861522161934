
.page {
  display: flex;
  flex-direction: column;
  padding-top: $portal-header-height;
  height: 100vh;
}

.page__referral {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page--rewards_catalog {
  padding-top: #{$portal-header-height}; // TODO: Verify this
}

.page__content {
  min-height: 1px; // Fixes IE11 layout issue US-52218
  flex: 1 0 auto;
}

.page__top-banner {
  padding-top: #{$portal-header-height + $portal-top-banner-height};
}

.page__title {
  font-size: $h4-font-size;
  margin-bottom: $page-title-margin-bottom;
  text-align: center;
}

.page__footer {
  flex-shrink: 1;
}

@include media-breakpoint-up(lg) {
  .page {
    padding-top: #{$portal-header-height + $portal-primary-tier-height};
  }

  .page--rewards_catalog {
    padding-top: #{$portal-header-height + $portal-primary-tier-height};
  }

  .page__top-banner {
    padding-top: #{$portal-header-height + $portal-primary-tier-height + $portal-top-banner-height};
  }
}
