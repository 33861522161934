
.btn:not(.btn-link) {
  font-weight: bold;
}

.btn:not(:disabled) {
  cursor: pointer;
}

.btn-primary {
  color: #FFFFFF
}

.btn-tertiary {
  border-color: currentColor;
}

.btn-cancel {
  @include button-variant($secondary, $secondary);
}

.btn-group > .btn + .btn {
  margin-left: 1px;
}

.form-check-input:checked {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #0d6efd !important;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #0d6efd;
}

.was-validated .form-check-input:valid:focus, .form-control.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}