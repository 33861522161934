.slide-parent {
  position: relative;
  width: 100%;
}

.slide-enter {
  transform: translate3d(-100%, 0, 0);
  transition-duration: 500ms;
  width: 100%;
}

.slide-enter.slide-enter-active {
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.slide-exit {
  transform: translate3d(0, 0, 0);
  width: 100%;
  position: absolute;
  top: 0;
}

.slide-exit.slide-exit-active {
  transform: translate3d(100%, 0, 0);
  transition-duration: 500ms;
  width: 100%;
  position: absolute;
  top: 0;
}
