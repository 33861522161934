
.text-prewrap { white-space: pre-wrap; }

.text-sm { font-size: $font-size-sm; }
.text-xl { font-size: $font-size-base * 3; }
.text-l { font-size: $font-size-base * 2; }
.text-underline { text-decoration: underline; }

.text-lh-normal { line-height: normal; }
.text-lh-1 { line-height: 1; }
