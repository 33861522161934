
.modal__spacer {
  display: none;
}

.modal-open .modal__spacer {
  display: block;
}

.modal-header > .btn-close {
  cursor: pointer;
  opacity: 0.7;
  background: transparent;
  color: #000;
}

.modal-header > .btn-close::after {
  content: 'x';
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
}

.modal-header .btn-close {
  padding: 1rem 1rem;
  margin: -2rem -1rem -1rem auto;
}
