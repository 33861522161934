.max-wrap {
	width: 100%;
	max-width: $max-wrap-width;
	margin: 0 auto;
}

.outline {
	box-shadow: 0 0 0 1px $white;
}

.was-validated {
	.is-invalid .invalid-feedback {
		display: block;
	}
}

input.ie11-force-invalid {
	border-color: #dc3545 !important;
}

.grey-feedback {
	color: #797979;
	font-size: 80%;
	margin-top: .25rem;
}

.bg-x-dark {
	background-color: #000;
}

.bg-highlight {
	background-color: #FFF963 !important;
}

.overflow-hidden {
	overflow: hidden;
}

.theme-highlight a:not(.btn) {
	color: inherit;
	text-decoration: underline;
}

.raised {
	box-shadow: $material-shadow-2;
}

.flourished {
	padding-bottom: 0.75rem;
	position: relative;
}

.flourished:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 52px;
	border-top: 1px solid $gray-500;
}

.inset {
	box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.25),
							inset 0 10px 10px -10px rgba(0, 0, 0, 0.25);
}

.padding-0 {
	padding: 0!important;
}