// Based off of http://tobiasahlin.com/spinkit/

@keyframes three-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.three-bounce {
  display: inline-block;
}

.three-bounce > * {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: currentColor;
  animation: three-bounce 1.4s infinite ease-in-out both;
}

.three-bounce > * + * {
  margin-left: 4px;
}

.three-bounce__one {
  animation-delay: -0.32s;
}

.three-bounce__two {
  animation-delay: -0.16s;
}
