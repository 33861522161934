.notification-container {
  position: fixed;
  margin: 0 15px;
  z-index: 1061;
  top: 25px;

  width: calc(100% - 30px);

  .notification {
    right: 0;
    position: relative;
    transition: right 500ms;
    margin-bottom: 20px;

    .alert {
      margin-bottom: 0;
    }

    &.closing {
      right: calc(-100% - 60px);
    }

    .timerIndicator {
      background-color: $white;
      opacity: 0.3;
      transition: width 200ms;
      display: block;
      width: 0;
      height: 5px;
      position: relative;
      bottom: 5px;
    }
  }

  label {
    color: $white;
  }

  .alert {
    border: 0;
    box-shadow: $material-shadow-2;

    .close {
      text-shadow: none;
    }
  }

  .btn {
    margin-top: 5px;
  }

  @include media-breakpoint-up(md) {
    width: calc(40% - 30px);
    right: 15px;
  }
}

// THEME
.notification-container {
  .notification {
    .alert {
      border-radius: 0;

      &.alert-danger {
        color: $white;
        background-color: $danger;

        + .timerIndicator {
          background-color: $white;
          opacity: 0.3;
        }
      }

      &.alert-warning {
        color: $gray-700;
        background-color: $yellow;

        + .timerIndicator {
          background-color: $gray-700;
          opacity: 0.3;
        }

        .close {
          color: $gray-700;
        }
      }

      &.alert-success {
        color: $white;
        background-color: $green;

        + .timerIndicator {
          background-color: $white;
          opacity: 0.3;
        }
      }
    }
  }
}
