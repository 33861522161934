
$material-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$material-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

$portal-header-height: 61px;
$portal-top-banner-height: 65px;
$portal-primary-tier-height: 35px;
$portal-rewards-catalog-btn-height: 56px;
$portal-footer-height: 28px;

$progress-bar-enhanced-transition: transform 0.6s ease;
$progress-fixed-height: 25px;
