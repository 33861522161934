.claim-list__claim-date {
    font-size: 0.75rem;
    color: #535353;
}

.claim-list__reward-info {
    font-size: 1.125rem;
    font-weight: bold;
}

.claim-list__question-answers {
    font-size: 0.875rem;
}

.claim-list__promotion-info {
    font-size: 1rem;
}

.claim-list__claim-data {
    font-size: 0.875rem;
    font-style: italic;
}

.claim-list__claim-card {
    border-radius: 0;

    &:not(.claim-list__claim-card--first) {
        border-top: 0;
    }

    &.claim-list__claim-card--dark {
        background-color: #f6f7fa;
    }
}

.claim-list__claim-card-action-required {
    margin-bottom: 4px;
    border:0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.claim-list__claim-card-action-required__link {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    align-items: center;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: #000;
    }
}

.claim-list__end-msg {
    font-weight: bold;
    text-align: center;
}

.claim-list__claim-card__link-body {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    align-items: center;

    text-decoration: none;
    color: #000;

    &:hover {
        text-decoration: none;
        color: #000;
    }
}
            
.claim-list__claim-card__copy {
    flex: 1 1 90%;
}

.claim-list__date-filter-column {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.claim-list__date-filter-container {
    width: 100%;
}

.claim-list__date-filter-text {
    font-weight: bold;
    text-align: center;
}

.claim-list__claim-filter-label {
    font-weight: bold;
    margin-bottom: 0;
}