.progress--enhanced {
  background-color: $white;
  border: none;
  position: relative;
}

.progress--enhanced--overlay {
  background-color: rgba(255, 255, 255, .4);
}

.progress--enhanced--fixed {
  height: $progress-fixed-height;
}

.progress-bar--enhanced {
  height: 100%;
  position: absolute;
  right: 100%;
  top: 0;
  transition: $progress-bar-enhanced-transition;
  width: 100%;
}
