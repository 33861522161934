// removes dropdown outline on mobile
.dropdown-menu {
  border-width: 0;
  padding-left: 16px;
}

.dropdown .dropdown-toggle::after {
  transition: transform .2s $ovations-ease-in-out;
  transform: rotate(-90deg);
}

.dropdown.show .dropdown-toggle::after {
  transform: rotate(0);
}

@include media-breakpoint-up(lg) {
  // resets to original dropdown settings
  .dropdown-menu {
    border-width: 1px;
    padding-left: 0;
  }

  .dropdown .dropdown-toggle::after,
  .dropdown.show .dropdown-toggle::after {
    transition: unset;
    transform: rotate(0);
  }
}

// Dropdown (as a nav item)
.dropdown.nav-item .dropdown-toggle {
  border-radius: 0;
  width: 100%;
  text-align: left;
}

.dropdown.nav-item .dropdown-menu {
  margin-top: 1px;
}

.form-select {
  appearance: auto;
  -webkit-appearance: auto;
  background-image: none;
}
