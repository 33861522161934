 // !importants override widget's css's extremely high specificity

.lhnHocChatBtn {
  background: none !important; // Background is controlled by this element's parent
}

.lhnWindow-header-avatar {
  display: none !important; // Avatar image would only ever be placeholder image
}

.lhnWindow-header-controls {
  float: right !important; // Controls become misaligned when avatar image is hidden
}

.lhnWindow-header-controls * + * {
  margin-left: 10px !important;
}

#lhnHocInvite.lhncancel {
  background: none !important; // lhn uses .lhncancel both as a button class and animation class
}

// Selectors below taken from widget's css

// Add box-shadow to "invite" prompt so white-on-white looks better
div#lhnHocInvite {
  overflow: visible;
}

div#lhnHocInvite div.lhnHocInviteCont {
  background: $white;
  color: $body-color;
  box-shadow: $material-shadow-2;
}

// Add semi-transparent background to header controls to avoid white-on-white
#lhnHelpOutCenter div.lhnWindow-header div.lhnWindow-header-controls img {
  background: rgba(0, 0, 0, 0.15);
  padding: 5px;
  border-radius: 3px;
}

#lhnHelpOutCenter div.lhnWindow-header button.lhnWindow-header-closer,
#lhnHelpOutCenter div.lhnWindow-header button.lhnWindow-header-minimizer {
  margin-top: 0;
}

// Use site font for live chat button
div#lhnHocButton div.lhnHocChatTxtCont span {
  font-size: 1rem;
  font-family: inherit;
  letter-spacing: normal;
}

// Remove borders from some buttons that lhn specifically added (unnecessarily)
#lhnHelpOutCenter div.lhnPrompt button.lhnFormButton {
  border: none;
}
