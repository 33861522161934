.modal-divider:before,
.modal-divider:after {
  content: '';
  position: absolute;
  background-color: $gray-200;
  width: 40%;
  height: 1px
}

.modal-divider:before {
  left: 0;
}

.modal-divider:after {
  right: 0;
}

@include media-breakpoint-up(lg) {
  .modal-divider:before,
  .modal-divider:after {
    width: 1px;
    height: 40%;
    left: 50%;
  }
  
  .modal-divider:before {
    top: 0;
  }
  
  .modal-divider:after {
    bottom: 0;
  }
}
