@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1140px;
  }
}

.container--enhanced {
  overflow: hidden;
  position: relative;
}

.container--enhanced__media {
  bottom: 0;
  left: 50%;
  min-width: 100%;
  position: absolute;
  transform: translateX(-50%);
}

.container--enhanced__media > img {
  min-width: 100%;
}

.container--enhanced__media__overlay {
  content: '';
  display: block;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
}

@include media-breakpoint-up(lg) { // Note: Preferred breakpoint definition via Bootstrap mixin.
  .container--enhanced__media {
    bottom: 50%;
    position: absolute;
    left: auto;
    min-width: auto;
    right: 0;
    transform: translateY(50%);
  }

  .container--enhanced__media > img {
    min-width: auto;
  }
}