.row-segmented > * + * {
  margin-top: 40px;
  padding-top: 40px;
  position: relative;
}

.row-segmented > * + *:before {
  content: "";
  background-color: $gray-400;
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: calc(100% - 30px);
}

@include media-breakpoint-up(lg) { // Note: Preferred breakpoint definition via Bootstrap mixin.
  .row-default\@lg > * + * {
    margin-top: 0;
    padding-top: 0;
  }

  .row-default\@lg > * + *:before {
    display: none;
  }
}