.progress-wrapper {
  margin-bottom: 32px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  height: $progress-fixed-height;
}

.progress-wrapper__icon {
  margin-top: 3px;
  transition: $progress-bar-enhanced-transition;
  text-align: left;
}

.progress-wrapper__icon > * {
  transform: translateX(-50%);
}

@media (min-width: 481px) {
  .progress-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}