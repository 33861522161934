
$navlink-padding-x: 15px;

.navbar {
  background: $white;
  box-shadow: $material-shadow;
  display: flex;
  flex-wrap: wrap;
}
.navbar .container {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-rewards-bar {
  background: none;
  padding: 0;
  box-shadow: none;
  z-index: -1;
}

.rewards-bar-container {
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.navbar-toggler {
  border: none;
  padding: 9px 10px;
}

.navbar-toggler .icon-bar {
  width: 22px;
  height: 2px;
  border-radius: 1px;
  display: block;
  background-color: $gray-600;
}

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.navbar--header {
  padding: 0;
}

.navbar--header .navbar-brand {
  height: $portal-header-height;
}

.navbar--header > .container {
  padding-left: 15px;
  padding-right: 15px;
}

.navbar--header .nav-item a,
.navbar--header .nav-item button {
  display: block;

  // !important to override bootstrap .navbar-expand-lg styles
  padding-left: $navlink-padding-x !important;
  padding-right: $navlink-padding-x !important;
  color: #007bff;
  text-decoration: none;
}

.nav-link.active {
  color: #007bff !important;
}

.navbar--header .nav-item .btn-primary {
  color: #FFFFFF !important;
}

.navbar--header .nav-item a,
.navbar--header .nav-item .btn {
  font-size: 18px; // Match v1 portal
}

.navbar__top-banner {
  width: 100%;
  color: #fff;
  height: $portal-top-banner-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include media-breakpoint-down(lg) {
  .navbar--header .navbar-collapse {
    margin: 0 #{$navlink-padding-x * -1};
    border-top: 1px solid transparent;
  }

  .navbar--header .navbar-nav {
    padding: 0 $navlink-padding-x;
  }

  .navbar--header .nav-item a .nav-link {
    margin: 0 #{$navlink-padding-x * -1};
  }

  .navbar--header .container {
    max-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .navbar--header .navbar--header-language-selector .nav-link {
    background: none;
    border: none;
    font-size: 0.8rem;
    padding: 0 0.5rem !important;
  }

  .navbar--header .navbar--header-language-selector .nav-link:hover {
    background: transparent;
  }
}

@include media-breakpoint-up(lg) {
  .navbar--header .nav-item {
    display: flex;
    align-items: center;
  }

  .navbar--header .nav-item .nav-link:not(.dropdown-item) {
    height: $portal-header-height;
    display: flex;
    align-items: center;
  }

  .navbar--primary-tier .nav-link {
    display: inline;
  }

  .navbar--primary-tier li:not(:first-child):before {
    content: " | ";
  }
}

.navbar--footer {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar--footer .nav-link {
  display: inline-block;
}

.navbar--footer .nav-link,
.navbar--footer .navbar-text {
  line-height: 1.1;
}

.navbar--footer--live-chat-enabled {
  padding-bottom: 50px; // Approximate height of fixed position live chat button
}

@include media-breakpoint-down(md) {
  .navbar-rewards-bar {
    display: none;
  }
}