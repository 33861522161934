
body {
  overflow-x: hidden;
  overflow-y: scroll;
}

button:not(:disabled) {
  cursor: pointer;
}

a {
  color: #007bff;
  text-decoration: none;
}